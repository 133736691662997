import React from "react"
import Service from "../../components/Shared/Service"
import checkMark from "../../assets/icons/noun-check-mark.svg"
import devopslifecycle from "../../assets/icons/devopsLifeCycle.svg"
import migrateToCloud from "../../assets/icons/migrateToCloud.svg"
import applicationModernization from "../../assets/icons/applicationModernization1.svg"
import { graphql } from "gatsby"

const seoTitle = "Application Modernization "
const seoDescription =
  "Atyeti's modernization capability helps you determine the right path to take to improve your current applications or to replace them with entirely new ones."
const keyword =
  " Application modernization, modernization, Kubernetes, Microservices, Refactoring, DevOps, Agile, Cloud Migration Automated Testing, Replatforming"
const mainHeading = "Application Modernization"
const mainPara = `As the world accelerates toward a digital economy, our modernization capability helps you determine the right path to take to improve your current applications - or to replace them with entirely new ones.`

const serviceIcons = [
  { id: 1, name: "Kubernetes", icon: checkMark },
  { id: 2, name: "Microservices", icon: checkMark },
  { id: 3, name: "Refactoring", icon: checkMark },
  { id: 4, name: "DevOps", icon: checkMark },
  { id: 5, name: "Agile", icon: checkMark },
  { id: 6, name: "Cloud Migration", icon: checkMark },
  { id: 7, name: "Automated Testing", icon: checkMark },
  { id: 8, name: "Replatforming", icon: checkMark },
]

const section2Heading = `Our Services`
const section2Para = `Atyeti can help in you migrate and build applications by modernization patterns such as rehosting, refactoring, replatforming, rearchitecting or replacing applications to achieve a scalable path to the cloud.`
const cardItems = [
  {
    id: 1,
    icon: applicationModernization,
    heading: `Modernization`,
    listItems: (
      <>
        <li>
          {" "}
          Build new applications, Re-build, re-platform, and re-imagine your
          apps and data.
        </li>
        <li>
          {" "}
          Take advantage of modern web, data, and cloud platforms and
          technologies.
        </li>
        <li>Modernization Services drive reduced cost, risk and increased agility</li>
        {/* <li>
          {" "}
          Atyeti can provide you with options for modernization going forward.
        </li> */}
      </>
    ),
  },
  {
    id: 2,
    icon: migrateToCloud,
    heading: `Migrate to the Cloud`,
    listItems: (
      <>
        <li>
        Discovery assessment of applications to validate the alignment to the target architecture.
        </li>
        <li>
          Validate foundational services including Governance, Security, Pipelines, Networking, Identity etc.
        </li>
        <li>
        Atyeti’s wave-based, factory model can provide a structured and efficient migration framework.
        </li>
      </>
    ),
  },
  {
    id: 3,
    icon: devopslifecycle,
    heading: `Life Cycle- DevOps`,
    listItems: (
      <>
        <li>
          Atyeti can help you break down the barriers between your development
          and IT operations teams.{" "}
        </li>
        <li>
          Deliver better software, faster, with higher quality and at a lower
          cost.
        </li>
        <li> Provide continuous delivery with high software quality. </li>
        <li>Dev(Sec)Ops tools and processes.</li>
      </>
    ),
  },
]

const ServiceDetails = ({ data }) => (
  <Service
    seoTitle={seoTitle}
    mainHeading={mainHeading}
    mainPara={mainPara}
    serviceIcons={serviceIcons}
    // section2Heading={section2Heading}
    cardItems={cardItems}
    section2Para={section2Para}
    caseItems={data}
    seoDescription={seoDescription}
    keyword={keyword}
    className="application-modernization"
  />
)
export const query = graphql`
  query ApplicationQuery {
    casestudyJson(mainheading: { eq: "application-modernization" }) {
      data {
        heading
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
        para
        slug
        subheading
      }
      bannerimage {
        heading
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`
export default ServiceDetails
